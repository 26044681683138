import { Link } from 'gatsby';
import * as React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const DataProtectionPage = () => (
    <Layout>
        <SEO
            title="Privacy policy"
            description="Trust, like art, never comes from having all the answers. It comes from being open to answer all questions."
        />
        <section className="padded full-height wrapper magazine" id="Privacy policy">
            <div className="">
                <h1>Privacy policy</h1>
                <span className="text--small">last updated 26 February 2022</span>
            </div>
            <div>
                <p>
                    Trust, like art, never comes from having all the answers. <br />
                    It comes from being open to answer all questions.
                </p>
                <section className="legal" id="Analytics">
                    <h2>No analytics</h2>
                    <p>
                        We don't analyze the traffic on this website. As such, there are no script
                        trackers, nor cookie trackers set on your browser.
                    </p>
                    <p>
                        This website is SSL-encrypted, ensuring your conection with our website is
                        secure and no unauthorized third parties can set cookies or tracker on your
                        browser through the usage of this website.
                    </p>
                </section>
                <section className="legal" id="Hosting">
                    <h2>Hosting</h2>
                    <p>
                        All ressources on this website are hosted on{' '}
                        <a target="_new" className="link" href="https://www.netlify.com/privacy/">
                            netlify
                        </a>
                        , ensuring that no tracking pixels or cookies are set on your browser
                        through the usage of this website.
                    </p>
                </section>
                <section className="legal" id="Fonts">
                    <h2>Not even Google Fonts </h2>
                    <p>
                        In order to prevent data leaks to big tech, we even host our fonts
                        ourselves, preventing unncessary traffic from and to external servers.
                    </p>
                </section>
                <section className="legal" id="Hosting">
                    <h2>Personal data</h2>
                    <p>
                        We don't collect any personal data actively from this website. You might
                        might want to send us messages through our contact form. You are free to
                        input any data to enable communication, such as your name and your email
                        address.
                    </p>
                    <p>
                        This personal data will not be shared with our partners without your
                        consent.
                    </p>
                    <p>
                        We will handle your personal data conidentially and use it strictly for the
                        purpose of communication and/or billing.
                    </p>
                    <p> You can request the deletion of your data in accordance to European law.</p>
                </section>
                <section className="legal" id="contact">
                    <h2>Contact us</h2>
                    <p>
                        If you have any questions or suggestions regarding our Privacy Policy,
                        please contact us at{' '}
                        <Link to="/contact" className="link">
                            contact(at)castrum-doloris.design
                        </Link>
                        .
                    </p>
                </section>
            </div>
        </section>
    </Layout>
);

export default DataProtectionPage;
